import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import infoStyles from "../styles/pages/info.module.scss"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function Info() {
  const { infoData } = useSiteMetaData()
  return (
    <Layout bgColor={infoData.background_color}>
      <section className={infoStyles.info_blurb}>
        <h2>*Pourquoi les images ne sont pas de bonne qualité ?</h2>
          <p>Graph & desgn est un site <strong>éco-conçu</strong>. C'est à dire qu'il met en place les bonnes pratiques qui garantissent un minimum d'impact environnemental lors de son chargement.</p>
          <p>Le numérique est un secteur polluant de par sa nature énergivore. En ce qui concerne le web, le poids des images est en grande partie responsable des temps de chargement des sites et du besoin énergétique necessaire à leur affichage.</p>
          <p>En faisant le choix de réduire le poids des visuels, nous réduisons le besoin en bande passante et optimisons les temps de chargement. Nous réduisons donc ce besoin en énergie.</p>
          <p>Bien sûr, ce parti-pris à un impact esthétique non négligeable. Mais les images n'étant là que pour illustrer, l'idée est de réduire leur poids sans pour autant diminuer le sens de leur présence.</p>
          <p>La technique utilisée est appelé "dithering" ou "haltoning". Cette méthode était utilisée par les jeux vidéo et le pixel art à une époque ou la mise à disposition de mémoire et de bande passante était limitée. C'est donc à la fois un besoin de notre époque et un hommage à une époque ou le champ des possible était ouvert. Une charmante époque où le web se rêvait décentralisé.</p>
          <div id="wcb" class="carbonbadge"></div>
          <script src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js"></script>
          <Link to="/">
          <h3>Fermer</h3>
          </Link>
      </section>

    </Layout>
  )
}
